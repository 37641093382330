import React from "react";
import { Container, Row, Col, Button, Navbar, Nav, Form, FormControl} from 'react-bootstrap'; 
import { useAuth0 } from "@auth0/auth0-react";
import StandardImage from '../images/Team_1.PNG';

import WebApplication from '../images/WebApplication.png';
import MobileApp from '../images/MobileApp.PNG';
import HomeAutomation from '../images/HomeAutomatio.png';

import Card from 'react-bootstrap/Card';

 import Footer from './Footer';
 import MainPageCursol from "./MainPageCursol";

 import {  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBIcon,
  MDBBtn,
  MDBRipple, } from 'mdb-react-ui-kit';

const Home = () => {
  const { isAuthenticated, logout } = useAuth0();
  return (
    <>
    <MainPageCursol/>
    <MDBContainer fluid className="my-5 text-center" >
    <center>
    <h4 className="mt-4 mb-5">
        <strong>Our Services</strong>
      </h4>
      <h5>
      <p className="font-main">At Bairagi Consultants, we are committed to transforming your ideas into innovative software solutions. As a dynamic startup, we bring fresh perspectives and cutting-edge technology to help your business thrive in the digital age. Our range of services includes:.</p>
      </h5>

     <MDBRow>
        <MDBCol md="12" lg="4" className="mb-4">
          <MDBCard>
            <MDBRipple
              rippleColor="light"
              rippleTag="div"
              className="bg-image rounded hover-zoom"
            >
              <MDBCardImage
                src={WebApplication}
                fluid
                className="w-100"
              />
                <a href="https://bairagi-consultants.com/#/WebApplication">
                <div className="mask">
                  <div className="d-flex justify-content-start align-items-end h-100">
                    <h5>
                     
                    </h5>
                  </div>
                </div>
                <div className="hover-overlay">
                  <div
                    className="mask"
                    style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                  ></div>
                </div>
              </a>
            </MDBRipple>
            <MDBCardBody>
            <button class="btn btn-primary btn-lg btn-block mr-5" data-toggle="animation" data-animation-target="#animate-click">
                Web Application
           </button>
           <h6 className="card-title mt-2 mb-3">Tailored solutions designed to meet your business needs.</h6>
           </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol md="6" lg="4" className="mb-4">
          <MDBCard>
            <MDBRipple
              rippleColor="light"
              rippleTag="div"
              className="bg-image rounded hover-zoom"
            >
              <MDBCardImage
                src={MobileApp}
                fluid
                className="w-100"
              />
              <a href="https://bairagi-consultants.com/#/MobileApp">
                <div className="mask">
                  <div className="d-flex justify-content-start align-items-end h-100">
                    <h5>
                
                    </h5>
                  </div>
                </div>
                <div className="hover-overlay">
                  <div
                    className="mask"
                    style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                  ></div>
                </div>
              </a>
            </MDBRipple>
            <MDBCardBody>
       
            <button class="btn btn-primary btn-lg btn-block mr-5" data-toggle="animation" data-animation-target="#animate-click">
            Mobile App
    </button>
              <h6 className="card-title mt-2 mb-3">Innovative and user-friendly apps for iOS and Android</h6>
            
            
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol md="6" lg="4" className="mb-4">
          <MDBCard>
            <MDBRipple
              rippleColor="light"
              rippleTag="div"
              className="bg-image rounded hover-zoom"
            >
              <MDBCardImage
                src={HomeAutomation}
                fluid
                className="w-100"
              />
              <a href="https://bairagi-consultants.com/#/HomeAutomation">
                <div className="mask">
                  <div className="d-flex justify-content-start align-items-end h-100">
                    <h5>
            
                    </h5>
                  </div>
                </div>
                <div class="hover-overlay">
                  <div
                    className="mask"
                    style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                  ></div>
                </div>
              </a>
            </MDBRipple>
            <MDBCardBody>
            <a href="http://localhost:3000/#/about" button class="btn btn-primary btn-lg btn-block mr-5" data-toggle="animation" data-animation-target="#animate-click">
       
            Home Automation
            </a>
             <h6 className="card-title mt-2 mb-3">Building automation for a smart home</h6>
                        
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      </center>
  </MDBContainer>
     
  </>
    
     
  );
};

export default Home;
