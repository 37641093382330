import React from 'react'
import { Footer, Navbar } from "../components";
import styles from './Aboutus.css';


const AboutPage = () => {
  return (
    <>
      <Navbar />
      <div className="row">
    <div className="col-12 ">
    
      </div> 
      </div>
      <div className="container my-3 py-3">
     <p class="lead text-start fw-bold">About Us:</p>
<p className="lead text-start" >Welcome to Bairagi Consultants!
At Bairagi Consultants, we are passionate about technology and innovation. Our mission is to create cutting-edge software solutions that drive business success and transform the way companies operate.
.</p>

<p class="lead text-start fw-bold">Our Story</p>
<p className="lead text-start">        
Our story began with a shared vision: to leverage technology to address real-world challenges and create meaningful impact. As a diverse team of software developers, designers, and strategists, we set out to build a company that embodies excellence, creativity, and a customer-first approach.
        </p>

        <p class="lead text-start fw-bold">Our Vision</p>
<p className="lead text-start">        
We envision a future where technology is the cornerstone of business success. Our goal is to be at the forefront of the software industry, delivering solutions that not only meet but anticipate the changing needs of our clients.         
</p>

<p class="lead text-start fw-bold">Our Mission</p>
<p className="lead text-start">        
Our mission is to provide high-quality, reliable software solutions that help businesses achieve their goals. We are committed to:
</p>
<ul class="b">
  <li><p class="fontt">Innovation: Continuously exploring new technologies and methodologies to deliver the best solutions.</p> </li>
  <li><p class="fontt">Quality: Ensuring that every product and service we offer meets the highest standards</p>  </li>
  <li><p class="fontt">Customer-Centricity:Deeply understanding our clients' needs and consistently exceeding their expectations</p>  </li>
</ul>

<p class="lead text-start fw-bold">What We Do</p>
<p className="lead text-start">        
At Bairagi Consultants, we specialize in a wide range of software solutions, including:
</p>
<ul class="b">
  <li><p class="fontt">Custom Software Development: Tailored solutions designed to meet the unique needs of your business.</p> </li>
  <li><p class="fontt">Mobile App Development: Innovative and user-friendly apps for iOS and Android platforms.</p>  </li>
  <li><p class="fontt">Web Development: Engaging and responsive websites that enhance your online presence</p>  </li>
  <li><p class="fontt">Cloud Solutions: Scalable and secure cloud services to streamline your operations.</p>  </li>
</ul>

<p class="lead text-start fw-bold">Our Team</p>
<p className="lead text-start">        
Our team is our greatest strength. We are a collective of talented and passionate professionals committed to delivering outstanding results. Each team member brings a unique set of skills and insights, fostering a culture of innovation and collaboration.
</p>

<p class="lead text-start fw-bold">Why Choose Us?</p>
<ul class="b">
  <li><p class="fontt">Experience: Our team has a proven track record of delivering successful projects across various industries</p> </li>
  <li><p class="fontt">Commitment: We are committed to our clients' success and work tirelessly to ensure their satisfaction.</p>  </li>
  <li><p class="fontt">Innovation: We stay ahead of industry trends to provide cutting-edge solutions.</p>  </li>
  <li><p class="fontt">Cloud Solutions: Scalable and secure cloud services to streamline your operations.</p>  </li>
</ul>
<p className="lead text-start">        
Thank you for considering Bairagi Consultants for your software needs. We look forward to partnering with you and helping your business thrive in the digital age.
</p>



</div>

        

      <Footer />
    </>
  )
}

export default AboutPage