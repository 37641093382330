import React from "react";
import { Container, Row, Col, Button, Nav, Form, FormControl} from 'react-bootstrap'; 
import { useAuth0 } from "@auth0/auth0-react";
import StandardImage from '../images/Team_1.PNG';
import { Footer, Navbar } from "../components";
import HeaderMobileApp from './HeaderMobileApp';

const MobileApp = () => {
  const { isAuthenticated, logout } = useAuth0();
  return (
    <>
     <Navbar />
     <HeaderMobileApp/>
     <p className="font-caption">Advanced and Scalable Architecture.</p>
     <p className="paragraph_text">A well-designed mobile application compatable for both Android and IOS</p>    
     <p className="font-caption">Front-end Technology.</p>
     <p className="paragraph_text">Responsive UI with React-Native, Bootstrap etc.</p>    
    
     <div  className='d-flex justify-content-center align-items-center p-2'
        style={{ width: '75%', height: '30%' }}>  
      <img  
      className="w-100 h-0"  
      src="./assets/Webapp/react-native.PNG" 
      alt="FrontEnd Technology"  
    />  
    </div>
    <p className="font-caption">Back-end Technology.</p>
    <p className="paragraph_text">Technologies being used as backend development are Java, Node.JS, Python, PHP Laravel etc.</p> 
    
     <div  className='d-flex justify-content-center align-items-center p-2'
        style={{ width: '75%', height: '50%' }}>  
      <img  
      className="w-100 h-0"  
      src="./assets/Webapp/Android.PNG" 
      alt="FrontEnd Technology"  
    />  
    </div>
     
     <Footer />
  </>
    
     
  );
};

export default MobileApp;
