import React from "react";
import { Container, Row, Col, Button, Nav, Form, FormControl} from 'react-bootstrap'; 
import { useAuth0 } from "@auth0/auth0-react";
import StandardImage from '../images/Team_1.PNG';
import { Footer, Navbar } from "../components";
import HeaderHomeAutomation from './HeaderHomeAutomation';

const HomeAutomation = () => {
  const { isAuthenticated, logout } = useAuth0();
  return (
    <>
   <Navbar />
   <HeaderHomeAutomation/>
   <p className="font-caption">Advanced and Scalable Architecture.</p>
     <p className="paragraph_text">Nice application to build and suport Home Aotumation using Arduino uno </p>    
     <p className="font-caption">Technology Using</p>
     <p className="paragraph_text">Arduino, Sensors with backend code written inn Python.</p>    
      <div  className='d-flex justify-content-center align-items-center p-2'
        style={{ width: '75%', height: '50%' }}>  
      <img  
      className="w-100 h-0"  
      src="./assets/Webapp/Arduino.PNG" 
      alt="Arduino Technology"  
    />  
      
    </div>
   <Footer />    
  </>
    
     
  );
};

export default HomeAutomation;
