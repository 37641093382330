import React from "react";
import {Carousel } from 'react-bootstrap'; 
import { useAuth0 } from "@auth0/auth0-react";
import './MainPageCursol.css';

const MainPageCursol = () => {
  const { isAuthenticated, logout } = useAuth0();
  return (
    <>
     <div  className='d-flex justify-content-center align-items-center p-2'
        style={{ width: '100%', height: '50%' }}>  
    <Carousel data-bs-theme="dark">  
    <Carousel.Item>  
     <img className="d-block w-100"  
      src="./assets/frontpage.png" 
      text="First slide" 
      />  
     <Carousel.Caption className="carousel-caption-New">  
      <h1 className="font-cursol">Digital Transformation</h1>  
      <p className="font-cursol-bottom">Digital Transformation with Bairagi Consultants</p>  
    </Carousel.Caption>  
  </Carousel.Item>  
 
  <Carousel.Item>  
    <img  
      className="d-block w-100"  
      src="./assets/2ndPage.png" 
      alt="Third slide"  
    />  
    <Carousel.Caption className="carousel-caption-New">  
      <h1 className="font-cursol">Online Business Footprint..</h1>  
      <p className="font-cursol-bottom">E-Commerce Solution</p>  
    </Carousel.Caption>  
   </Carousel.Item>  
  <Carousel.Item>  
    <img  
      className="d-block w-100"  
      src="./assets/BusinessApplication.png"
      alt="Fourth slide"  
    />  
    <Carousel.Caption className="carousel-caption-New">  
      <h1 className="font-cursol">Streamline customer interactions with Business..</h1>  
      <p className="font-cursol-bottom">CRM Software</p>  
    </Carousel.Caption>  
   </Carousel.Item>

  <Carousel.Item>  
    <img  
      className="d-block w-100"  
      src="./assets/HomeAutomation.png"
      alt="Fifth slide"  
    />  
  
    <Carousel.Caption className="carousel-caption-New">  
      <h1 className="font-cursol">Smart management of home..</h1>  
      <p className="font-cursol-bottom">Home Automation</p>  
    </Carousel.Caption> 
   </Carousel.Item>  

  <Carousel.Item>  
    <img  
      className="d-block w-100"  
      src="./assets/MobileApp.png"
      alt="Fifth slide"  
    />  
    <Carousel.Caption className="carousel-caption-New">  
      <h1 className="font-cursol">Your Perfect Partner in Communication..</h1>  
      <p className="font-cursol-bottom">Mobile App </p>  
    </Carousel.Caption>  
   </Carousel.Item>
</Carousel>  
</div>  

    </>
    
     
  );
};

export default MainPageCursol;
