import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Footer, Navbar } from "../components";

export default function ContactPage()  {

  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

    const form = useRef();
    //console.log("Ln 7 ContactUs called.. ");
    const sendEmail = (e) => {
      e.preventDefault();
       
    };

const handleSubject=async(evt, param)=>{

      setSubject(param);
      //console.log("Entered Subject= " + subject);
    
    }
    
    const isDataValid = () => {
        if( subject.length == 0 || 
        message.length == 0 || 
        name.length == 0 || 
        email.length == 0 )
       return false;
         else
       return true;
    }

var templateParams = {
  message : "Your order is confirmed",
  name: 'Hi ' + name,
  subject: "Support and Customer Care team will reach you soon.. ",
  to_mail: email
 };
 
const InitiateMail =()=>{

  //console.log('Called InitiateMail Ln 47 ...');
  if(! isDataValid())
  {
    alert('Please enter valid data..');
    return;
  }
    

   emailjs.send('service_5l4zgi1','sunder_template_i9kyzm1', templateParams, 'xkVfdOdrH64JDAp3E')
    .then(function(response) {
       //console.log('Email Send SUCCESS!', response.status, response.text);
      alert("Sent email successfully..");
      setSubject("");
      setName("");
      setEmail("");
      setMessage("");
    }, function(error) {
       //console.log('FAILED to send mail...', error);
       alert("Sent email failed wit error "+ error);
    });
     
    }

    const handleName=async(evt, param)=>{

      setName(param);
      //console.log("LN 64 Entered name= " + name);
    
    }
    const handleEmail=async(evt, param)=>{

      setEmail(param);
      //console.log("Ln 70 Entered email= " + email);
    
    }
    const handleMessage=async(evt, param)=>{

      setMessage(param);
      //console.log("Entered Subject= " + message);
    
    }

    return (
      <>
        <Navbar />
        <div className="container bg-primary text-white my-3 py-3">
          <h1 className="text-center">Contact Us</h1>
          <hr />

          <div className="row my-3">
          <div className="col-4 mx-auto">
          <p class="fs-4">Contact Address</p>
          <p class="fs-5">Sujanbagan, P.O.- Chinsurah</p> 
          <p class="fs-5">Dist. - Hooghly, West Bengal, 712101 India</p>
          <p class="fs-4">Contact Number</p>
          <p class="fs-5">9432169467</p>
          <p class="fs-5">Mon.- Sat. 10:00 AM - 7:00 PM</p>
          <hr />
          </div>

          <div className="col-4 mx-auto">
          <p class="fs-4">Email US </p>
          <p class="fs-5">businesssupport@bairagi-consultants.com</p>
          <hr />
                    </div>
                    </div>
                    </div>
                    <Footer/>
      </>
    );
  };